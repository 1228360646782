
body {
    background-color: #292A31;
}

.square {
    width: 50px;
    height: 50px;    
 }

